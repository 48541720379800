import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { ApicallService } from 'src/app/services/apicall.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-students-zone',
  templateUrl: './students-zone.component.html',
  styleUrls: ['./students-zone.component.css']
})
export class StudentsZoneComponent implements OnInit {
  careersPlacementList: any;
  careersAssignCategory: any;
  filterCatogryList: any = [];
  careersPlacementData: any;
  videoUrl: any;
  constructor(private _service: CommonService, private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ngxSpinner: NgxSpinnerService, public sanitizer: DomSanitizer,
    private projectService: ProjectSeoService,
    private apiService: ApicallService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }
  }

  ngOnInit(): void {
    let tbody = {
      slug: 'studentsZone',
      Projectid: environment.projectid,
    };
    this.apiService.getGetseo(tbody).subscribe((data: any) => {
     this.projectService.sendMessagebread(data?.data?.breadcrumb);
    this.getCMSDataList();
    });

    
  }

  getCMSDataList() {
    let obj = {
      "Type": "STUDENT WORK",
      "Project_Id": 3
    }
    this._service.getCMSContent(obj).subscribe(
      res => {
        this.careersAssignCategory = JSON.parse(res.data[0].AssignCategory)
        this.careersPlacementList = JSON.parse(res.data[0].contentData)
        this.careersPlacementData = this.careersPlacementList
      }
    )
  }

  getCatagory(catId: any) {
    this.filterCatogryList = this.careersPlacementList.filter((x: any) => {
      return catId === parseInt(x.category)
    })
    this.careersPlacementData = this.filterCatogryList
  }
  playvideo(url:string){
    if(url){
      this.videoUrl =this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+url)
    }
   
  }
}
