import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';
import { CommonService } from '../../service/common.service';
@Component({
  selector: 'app-admission-lp',
  templateUrl: './admission-lp.component.html',
  styleUrls: ['./admission-lp.component.css']
})
export class AdmissionLPComponent implements OnInit {
  getVal: string = "";
  admissionForm: FormGroup;
  submitted = false;
  randomOtp: any;
  otpInput: boolean = false
  otp_msg: any;
  otp_ValidMsg: boolean = false
  otp_inValidMsg: boolean = false
  countryList: any;
  selectedDevice: any;
  stateList: any = [];
  cityList: any = [];
  franchiseeList: any;
  studentID: any;
  program_id: any;
  gen_captcha: any;
  getCourseList: any = [];
  filterFranchisee: any;
  centerName: any;
  centercode: string = "";
  utm_source: string = "Website";
  utm_medium: string = "Website";
  utm_compaign: string = "Website";
  gclid: string = "Website";
  utm_term: string = "Website";
  utm_content: string = "Website";
  utm_ad: string = "Website";
  fromcenterpage: any;
  constructor(private _router: Router, private fb: FormBuilder, private _servie: CommonService, private _activeRoute: ActivatedRoute,) {
    this.admissionForm = fb.group({
      fname: ['', Validators.required],
      // lname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      // pinCode: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{6}$")]],
      // country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      otp: ['', Validators.required],
      center: ['', Validators.required],
      course: ['', Validators.required],

    })
  }
  ngOnInit(): void {

    this._activeRoute.queryParams.subscribe((params: Params) => {
      this.utm_source = params["utm_source"] != null ? params["utm_source"] : this.utm_source;
      this.utm_medium = params["utm_medium"] != null ? params["utm_medium"] : this.utm_medium;
      this.utm_compaign = params["utm_campaign"] != null ? params["utm_campaign"] : this.utm_compaign;
      this.gclid = params["gclid"] != null ? params["gclid"] : this.gclid;
      this.utm_term = params["utm_term"] != null ? params["utm_term"] : this.utm_term;
      this.utm_content = params["utm_content"] != null ? params["utm_content"] : this.utm_source;
      this.utm_ad = params["utm_ad"] != null ? params["utm_ad"] : this.utm_ad;
    });
    this.selectCountry_State_cityList();
    this.getCourseList = this._servie.course
    this.fromcenterpage = (localStorage.getItem('iscenter')!);
    localStorage.removeItem('iscenter');
    if (this.fromcenterpage != 'true') {
      this.selectCountry_State_cityList();
    } else {
      this._servie.getcenterdt().subscribe(
        resp => {
          this.centercode = resp.Franchisee_Code
          this.centerName = resp.Franchisee_Name
        }
      );
    }
  }

  get f() {
    return this.admissionForm.controls;
  }

  validationForm() {
    this.submitted = true;
    if (this.admissionForm.invalid) {
      if (this.fromcenterpage == "true") {
        this.submitForm();
      }
      else {
        alert("All fields required");
      }
    } else {
      this.centercode = this.admissionForm.get('center')?.value;
      this.submitForm();
    }

  }

  submitForm() {
    if ((this.admissionForm.get('otp')?.value).length == 4) {
      if (this.randomOtp == this.admissionForm.get('otp')?.value) {
        let obj = {
          "City": this.admissionForm.get('city')?.value,
          "Country": "India",
          "Email": this.admissionForm.get('email')?.value,
          "FirstName": "",
          "HaveSpace": "",
          "LastName": this.admissionForm.get('fname')?.value,
          "Location": this.centercode,//id
          "Location_name": this.centerName,
          "Mobile": this.admissionForm.get('mobile')?.value,
          "class": this.admissionForm.get('course')?.value,
          "Product": "259262000033877033",
          "ProjectId": "3",
          "SoonStartsIn": "",
          "Source": this.utm_source,
          "gclid": this.gclid,
          "State": this.admissionForm.get('state')?.value,
          "Type": "P",
          "WillingToInvest": "",
          "utm_compaign": this.utm_compaign,
          "utm_medium": this.utm_medium,
          "utm_source": this.utm_source,
          "utm_ad": this.utm_ad,
          "utm_Content": this.utm_content,
          "utm_Term": this.utm_term,
          "Stream": ""


        }
        this._servie.saveData(obj).subscribe(
          res => {
            this.otp_ValidMsg = false;
            this.otp_inValidMsg = false;
            this._router.navigate(['/enquiryThankyou'])
            this.admissionForm.reset();
            this.submitted = false;
          }
        )
        this.otp_ValidMsg = true;
        this.otp_inValidMsg = false;

      } else {
        alert('Please Enter Valid OTP')
        this.otp_inValidMsg = true;
        this.otp_ValidMsg = false;
      }
    }


  }


  selectCentet(getCenter: any) {

    if (!Array.isArray(this.filterFranchisee[0].Franchisee)) {
      this.franchiseeList = [this.filterFranchisee[0].Franchisee];
      this.centerName = this.franchiseeList[0].Franchisee_Name
    }
    else {
      let dt = this.franchiseeList.filter((dt: any) => {
        return dt.Franchisee_Code == getCenter
      }).map((obj: any) => {
        return obj
      })
      this.centerName = dt[0].Franchisee_Name;
    }
  }
  getMobileNO() {
    if ((this.admissionForm.get('mobile')?.value).length == 10) {
      this.sendMobNO();
    }
  }

  sendMobNO() {
    this.randomOtp = Math.floor(1000 + Math.random() * 9000);
    let mobNo = {
      "MobileNo": this.admissionForm.get('mobile')?.value,
      "smsText": `To validate your ZICA interest use One Time Password ${this.randomOtp}. Think Education. Think Zee Learn.`,
      "sResponse": ""
    }
    this._servie.getOtp(mobNo).subscribe(
      res => {
        this.otpInput = true;
      }
    )
  }
  verifyOTP() {
    if ((this.admissionForm.get('otp')?.value).length == 4) {
      if (this.randomOtp == this.admissionForm.get('otp')?.value) {
        this.otp_ValidMsg = true;
        this.otp_inValidMsg = false;
      } else {
        this.otp_inValidMsg = true;
        this.otp_ValidMsg = false;
      }
    }
  }

  selectCountry_State_cityList() {
    this._servie.getState_countryList().subscribe(
      res => {
        this.countryList = res.root.subroot
        this.stateList = this.countryList.State.filter((x: any) => {
          // return x.State_Name
          return x.State_Name != 'Maharashtra' && x.State_Name != 'Rajasthan'
        })
      })
  }

  selectState(selectVal: any) {

    let state = selectVal.target.value
    let filterCity = this.stateList.filter((x: any) => {
      return x.State_Name == state
    })

    if (!Array.isArray(filterCity[0].City)) {

      this.cityList = [filterCity[0].City].filter((x: any) => {
        return x.City_Name
        //return x.City_Name == 'Mumbai' || x.City_Name == 'Jaipur'
      });
    }
    else {
      this.cityList = filterCity[0].City.filter((x: any) => {
        return x.City_Name
        // return x.City_Name == 'Mumbai' || x.City_Name == 'Jaipur'
      });
    }
  }

  selectCity(selectVal: any) {
    let city = selectVal.target.value
    this.filterFranchisee = this.cityList.filter((x: any) => {
      return x.City_Name == city
    })
    if (!Array.isArray(this.filterFranchisee[0].Franchisee)) {
      this.franchiseeList = [this.filterFranchisee[0].Franchisee];
      // this.franchiseeList =  this.franchiseeList.filter((x:any)=>{
      //   return x.Franchisee_Code !='W-1-005'
      // })
    }
    else {
      this.franchiseeList = this.filterFranchisee[0].Franchisee;
      // this.franchiseeList =  this.franchiseeList.filter((x:any)=>{
      //   return x.Franchisee_Code !='W-1-005'
      // })
    }
  }

  reload() {
    document.getElementById('trigger-overlay')?.click();
  }


  clickMenu(value: any) {
    // (document.getElementById("west") as HTMLFormElement).style.backgroundColor ='red';
    // (document.getElementById("west") as HTMLFormElement).style.color ='#FFF';
    document.getElementById("menuClose")?.click();
  }
  getadmissionpage() {
    localStorage.setItem('iscenter', 'false')
  }
}
