<section>
    <div class="main_body">
      <div class="bitCard_content">
        <app-sub-menu></app-sub-menu>
      </div>
      <div class="box1">
        <div class="row">
          <div class="col-md-6">
            <div class="left_box">
              <div class="text1">Resources / Our Events</div>
              <div class="text2">Let’s Explore Through<br>
                <span class="color_text">ZICA Events</span>
              </div>
              <!-- <div class="text_para">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua</p>
              </div> -->
            </div>
          </div>
          <div class="col-md-6">
            <div class="right_box">
              <!-- <div class="hang1"></div>
              <div class="hang2"></div>
              <div class="hang3"></div> -->
              <div class="">
                <div class="imgBox1">
                  <img src="../../../assets/img/zica/studentPhoto.png" class="studentImg img-fluid" alt="Our Events">
                </div>
                <!-- <div class="imgBox1 swing">
                  <img src="../../../assets/img/zica/studentG3.png" class="studentImg img-fluid" alt="Student Zone">
                </div>
                <div class="imgBox2 swing">
                  <img src="../../../assets/img/zica/studentG2.png" class="studentImg img-fluid" alt="Student Zone">
                </div> -->
              </div>
              <!-- <div class="secound_content mt-3">
                <div class="imgBox3 swing">
                  <img src="../../../assets/img/zica/studentG1.png" class="studentImg img-fluid" alt="Student Zone">
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
  
      <div class="box2">
        <div class="student_text">
          <img src="../../../assets/img/zica/student_gallery/studentLab.png" width="100%" alt="Student Zone">
        </div>
        <div class="">
          <div class="text2 text-center">Events
            <span class="color_text">Gallery</span>
          </div>
          <!-- <div class="text_para text-center">
            <p class="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua</p>
          </div> -->
        </div>
        <div class="body_content d-flex justify-content-center align-items-center">
          <div class="card p-3">
            <nav>
              <div class="nav nav-tabs mb-3 buttonList d-flex justify-content-between" id="nav-tab" role="tablist">
                <div class="button_content" *ngFor="let a of careersAssignCategory">
                  <button class="nav-link" id="nav-Manual-tab" data-bs-toggle="tab" data-bs-target="#nav-Manual"
                    type="button" role="tab" aria-controls="nav-Manual" aria-selected="true"
                    (click)="getCatagory(a.categoryId)">{{a.categoryname}}</button>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div class="imggallery">
          <div class="row">
            <div class="col-md-4 d-flex justify-content-center mt-4" *ngFor="let a of careersPlacementData">
                <div *ngIf="a.slug" class="video_content" (click)="playvideo(a.slug)">
                      <div class="imgList" *ngFor="let d of a.logofiles">
                        <a data-fancybox [attr.data-caption]="d.Title" href="{{videoUrl}}">
                          <i class='fa fa-play'></i> 
                          <img src="{{d.value}}" class="stdImg">
                        </a>
                      </div>
                  </div>
                <div *ngIf="!a.slug">
                  <div class="imgList" *ngFor="let c of a.logofiles">
                    <a data-fancybox [attr.data-caption]="c.Title" href="{{c.value}}">
                      <img src="{{c.value}}" class="stdImg">
                    </a>
                  </div>
                </div>              
            </div>
          </div>
        </div>
      </div>
      <div class="box3">
        <div class="row ">
          <div class="col-lg-6 col-6 text-white mobileRes">
            <div class="text2"><span class="color_text"> Get In Touch</span><br>
              With Us </div>
          </div>
          <div class="col-lg-6 col-6 text-white mobileRes">
  
            <div class="girl_img">
              <img src="../../../assets/img/zica/Enquiry_pic.png" width="100%" alt="Student Zone">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 desktopRes">
            <div class="text2"><span class="color_text"> Get In Touch</span><br>
              With Us </div>
            <div class="girl_img mt-2">
              <img src="../../../assets/img/zica/Enquiry_pic.png" alt="Student Zone">
            </div>
          </div>
          <div class="col-md-6">
            <div class="franchise_form_content">
              <app-amission-course></app-amission-course>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>