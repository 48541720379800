<div class="accordion_box">
    <div class="accordion" id="accordionExample">
        <div class="accordion-item" *ngFor="let a of faqsDataListFinel;let i = index">
          <div>
          <h2 class="accordion-header" [attr.id]="'headingOne'+i">
            <button class="accordion-button1" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne'+i" aria-expanded="true" [attr.aria-controls]="'collapseOne'+i">
                {{a.Title}}
            </button>
          </h2>
          <div [attr.id]="'collapseOne'+i" class="accordion-collapse collapse" [attr.aria-labelledby]="'headingOne'+i" data-bs-parent="#accordionExample">
            <div class="accordion-body" >
              <p [innerHTML]="a.Long1"></p>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-3">
      <div class="view_btn" *ngIf="showBtn" (click)="showMore()">View More</div>
      <div class="view_btn" style="margin-left: 7px;" *ngIf="closeBtn" (click)="closeMore()">close</div>
    </div>
    <!-- <div class="numOfFaqs">Showing {{fqindex}} records of {{getFaqCount}}</div> -->
</div>