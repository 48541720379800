import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-thankyou-partner',
  templateUrl: './thankyou-partner.component.html',
  styleUrls: ['./thankyou-partner.component.css']
})
export class ThankyouPartnerComponent {
  constructor(private renderer: Renderer2) {

  }
  ngOnInit(): void {
    this.addFacebookPixelScript();
     }
  private addFacebookPixelScript(): void { const script = this.renderer.createElement('script'); 
    script.type = 'text/javascript'; 
    script.text = ` !function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '190798700781785'); fbq('track', 'PageView'); fbq('track', 'CompleteRegistration'); 
    gtag('event', 'conversion', {'send_to': 'AW-11432336687/yMa7CLXhr7kZEK-6rssq'})`; 
    this.renderer.appendChild(document.head, script); const noscript = this.renderer.createElement('noscript'); const img = this.renderer.createElement('img'); 
    this.renderer.setAttribute(img, 'height', '1'); 
    this.renderer.setAttribute(img, 'width', '1'); 
    this.renderer.setAttribute(img, 'style', 'display:none'); 
    this.renderer.setAttribute(img, 'src', 'https://www.facebook.com/tr?id=190798700781785&ev=PageView&noscript=1'); 
    this.renderer.appendChild(noscript, img); this.renderer.appendChild(document.body, noscript);
    }
}
