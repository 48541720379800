import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
getVal: string="";
constructor(private _router:Router){}

reload(){
 
 document.getElementById('trigger-overlay')?.click();
}

closeMenu(){
  document.getElementById("menuClose")?.click();
}

clickMenu(value:any){
// (document.getElementById("west") as HTMLFormElement).style.backgroundColor ='red';
// (document.getElementById("west") as HTMLFormElement).style.color ='#FFF';
}
getadmissionpage(){
  localStorage.setItem('iscenter','false')
}
}