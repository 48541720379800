<section>
    <div class="main_body">
        <div class="bitCard_content">
            <app-sub-menu></app-sub-menu>
             </div>
        <div class="box1">
            <div class="row">
                <div class="col-md-6">
                    <div class="left_box">
                        <!-- <div class="text1">Resources / Awards & Recognition</div> -->
                        <div class="text2"> One Of The <br><span class="color_text">Top Institutes</span><br>in Animation
                        </div>
                        <div class="text_para">
                            <p>ZICA is the part of Zee Group, India’s Largest TV Network which comprises of Channels like Zee Tv, Zee Cinema, Zee News, & Tv, & Pictures etc. ZICA as a brand started its journey 20 yrs back in Mumbai. This institute is the brainchild of the Chairman of Zee Group Shri Subhash Chandra ji who is also known as the Media Baron of India
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="right_box">
                        <div class="img_box">
                            <img src="../../../assets/img/zica/bookGroup.png" width="100%" alt="About Us">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box2">
            <div class="text2 text-center">Zee Institute of Creative Art<span class="color_text"> (ZICA)</span></div>
            <div class="text_para">
               <div class="subPara text-center text-white mt-4"><b>Best Animation, VFX, Gaming and Graphic Design Institute in India since 1995.</b></div>
                <p></p>
                <p>Zee Institute of Creative Art (ZICA) is the India's first full-fledged Classical and Digital Animation Training Academy that trains youth in classical 2D and modern 3D animation. The past seventeen years has seen the institute nurture some of the brightest talents in the world of animation. The institute has adopted a novel training style and is focused entirely on creating a stimulating environment for its students.</p>
                <p>"Zee Institute of Creative Art, with its state-of-the-art infrastructure, gives practical training in 2D Animation, 3D Animation, Visual Effects & Gaming, covering the stages of visualization, Pre-production, production and post-production. At ZICA, a lot of emphasis is laid on the principles of art and design and fundamentals of classical animation, while extracting the same knowledge to computer based Digital animation. Students are also assured of creative exposure with a healthy mix of theory and practical sessions, delivered with the right amalgamation of Art & technology, under the supervision of in-house experienced faculties and industry experts from India and abroad." ZICA's Training Studio is equipped with high end animation tools, 2D animation facilities, 3D software, Visual FX Labs and imaging technologies. After every semester, a well laid out assessment procedure ensures the student's acceptability as dignified professionals in the world of animation.</p>
                <p>ZICA propagates the inherent artistic and aesthetic skills in each student, rather than only the software, which keeps on upgrading from time to time.</p>
                <p>Today ZICA, with over 30 centres in more than 15 major cities including Mumbai, Delhi, Bangalore, Hyderabad, Kolkata, Pune, Lucknow, Chandigarh, Bhubneshwar, Ahmedabad etc. emerges as young India's chosen partner to turn passion into profession.</p>
            </div>
        </div>

        <div class="box3 mt-4">
            <div class="row">
                <div class="col-md-6">
                    <div class="lagacyImg_content">
                        <div class="firstImgCOntent d-flex">
                            <div class="firstImgBox"><img src="../../../assets/img/zica/lagacy1.png" class="aniImg1" alt="About Us"></div>
                            <div class="firstImgBox"><img src="../../../assets/img/zica/lagacy2.png" class="secondImg aniImg2"
                                    alt="About Us"></div>
                        </div>
                        <div class="secontImgCOntent">
                            <div class="firstImgBox">
                                <img src="../../../assets/img/zica/lagacy3.png" alt="About Us" class="aniImg3">
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-6">
                    <div class="text2 text-center">Our <span class="color_text">Legacy</span></div>
                    <div class="text_para mt-4">
                        <div class="text_content"><img src="../../../assets/img/zica/icon/awardIcon.png" width="20px"
                                height="20px" alt="About Us">
                            <p>India’s premiere animation institute established in the year 1995</p>
                        </div>
                        <div class="text_content"><img src="../../../assets/img/zica/icon/awardIcon.png" width="20px"
                                height="20px" alt="About Us">
                            <p>Zee Learn is India’s leading company in education segment which caters wide range of
                                education from pre-school, school, coaching, competitive exams, formal and vocational
                                education</p>
                        </div>
                        <div class="text_content"><img src="../../../assets/img/zica/icon/awardIcon.png" width="20px"
                                height="20px" alt="About Us">
                            <p>PAN India presence</p>
                        </div>
                        <div class="text_content"> <img src="../../../assets/img/zica/icon/awardIcon.png" width="20px"
                                height="20px" alt="About Us">
                            <p>Thousands of alumni working in world renowned production studios</p>
                        </div>
                        <div class="text_content"> <img src="../../../assets/img/zica/icon/awardIcon.png" width="20px"
                                height="20px" alt="About Us">
                            <p>Students are the stalwarts in the animation, VFX & design industry</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center">
            <h2 class="newtxt">Zee Learn<span class="color_text">Limited Vision</span></h2>
            <div class="newtxt">Backed by Zee Group, Zee Learn aims to empower India's children and youth</div>
            <div class="newtxt" style="font-weight: bold;font-style: italic;">“We provide the environment to ignite, nurture and unleash your potential and talent.”</div>
        </div>
        <!-- <div class="box5">
            <div class="desktop_content">
            <div class="founder_main_content">
                <div class="founder_div">
          <img src="../../../assets/img/zica/founderImg.png" width="250px" height="270px" alt="About Us">
                </div>
                <div class="zicaFounder_text_content">
                    <div class="text2">ZICA<span class="color_text">Founder</span></div>
                    <p class="color_text mt-3">Dr. Subhash Chandra Member of Parliament, Rajya Sabha and Chairman, Essel
                        Group & ZEE</p>
                    <div class="text_para">
                        <p>Dr. Subhash Chandra (DSC) is an independent Member of the Upper House of Parliament from the
                            state of Haryana. He is also the Chairman of the Essel Group of Companies and is amongst the
                            leading lights of the global media & entertainment industry. A self-made man, and a true
                            visionary, DSC has consistently demonstrated his ability to identify new businesses and lead
                            them on the path of success.
                            DSC is rightly referred to as the Media Moghul. With his pioneering vision and
                            entrepreneurial mindset to achieve growth, he revolutionized the television industry by
                            launching the country's first satellite television channel - Zee TV in 1992 and later the
                            first private news channel, Zee News. </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="mobile_content">
            <div class="founder_main_content1">
                <div class="row">
                    <div class="col-md-6">
                        <div class="founder_div">
                            <img src="../../../assets/img/zica/founderImg.png" width="250px" height="270px" alt="About Us">
                                  </div>
                    </div>
                </div>
               <div class="col-md-6">
                <div class="zicaFounder_text_content1">
                    <div class="text2">ZICA<span class="color_text">Founder</span></div>
                    <p class="color_text mt-3">Dr. Subhash Chandra Member of Parliament, Rajya Sabha and Chairman, Essel
                        Group & ZEE</p>
                    <div class="text_para">
                        <p>Dr. Subhash Chandra (DSC) is an independent Member of the Upper House of Parliament from the
                            state of Haryana. He is also the Chairman of the Essel Group of Companies and is amongst the
                            leading lights of the global media & entertainment industry. A self-made man, and a true
                            visionary, DSC has consistently demonstrated his ability to identify new businesses and lead
                            them on the path of success.
                            DSC is rightly referred to as the Media Moghul. With his pioneering vision and
                            entrepreneurial mindset to achieve growth, he revolutionized the television industry by
                            launching the country's first satellite television channel - Zee TV in 1992 and later the
                            first private news channel, Zee News. </p>
                    </div>
                </div>
               </div>
          
            </div>
        </div>
    </div> -->

        <div class="box7">
            <div class="row">
                <div class="col-md-7">
                    <div class="">
                        <div class="text2 text-center">The Right Place to<br> Achieve<span class="color_text"> Your
                                Goals</span></div>
                        <div class="text_para">
                            <p>Zee Institute Of Creative Art (ZICA) Is India's First Full-Fledged Classical And Digital
                                Animation Training Academy Established In 1995, That Trains Youth In 2D Animation, 3D
                                Animation, Visual Effects (VFX), Gaming, Graphic Design, Web Design, Digital Marketing
                                And Digital Photography.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="right_goalsImg_box d-flex justify-content-center">
                        <img src="../../../assets/img/zica/Goals_img.png" width="280px" height="280px" alt="About Us">
                    </div>
                </div>
            </div>
            <div class="multi_box_content1 mt-3">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons.png" width="45px" height="45px" alt="About Us">
                    </div>
                    <p>India’s premiere traditional and digital animation institute</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons2.png" width="45px" height="45px" alt="About Us">
                    </div>
                    <p>Emphasize on pre-production which is very important</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons3.png" width="45px" height="45px" alt="About Us">
                    </div>
                    <p>Current industry standard and career focused course curriculum</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons4.png" width="45px" height="45px" alt="About Us">
                    </div>
                    <p>Personalised Attention to Students</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons5.png" width="45px" height="45px" alt="About Us">
                    </div>
                    <p>Collaboration with well-known university to provide animation VFX degree</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons6.png" width="45px" height="45px" alt="About Us">
                    </div>
                    <p>Dedicated online and in-class training platform for Students</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons7.png" width="45px" height="45px" alt="About Us">
                    </div>
                    <p>Access to world-class infrastructure & latest software</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons8.png" width="45px" height="45px" alt="About Us">
                    </div>
                    <p>Monthly & yearly activities to enhance competitive spirit among students</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons9.png" width="45px" height="45px" alt="About Us">
                    </div>
                    <p>E-Studio an exclusive online app for you to access e-books, on the got</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons10.png" width="45px" height="45px" alt="About Us">
                    </div>
                    <p>Fun-filled studio study environment</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons11.png" width="45px" height="45px" alt="About Us">
                    </div>
                    <p>Guidance to develop your own portfolio & show reel</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons14.png" width="45px" height="45px" alt="About Us">
                    </div>
                    <p>Opportunities for placements with top animation & VFX studios</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons15.png" width="45px" height="45px" alt="About Us">
                    </div>
                    <p>Student loan facility</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons13.png" width="45px" height="45px" alt="About Us">
                    </div>
                    <p>India’s premiere traditional and digital animation institute</p>
                </div>
                </div>
            </div>
            </div>
        </div>


        <div class="box4">
            <app-testimonial></app-testimonial>
        </div>

    <div class="box6">
        <div class="row ">
            <div class="col-lg-6 col-6 text-white mobileRes">
               
             <div class="text2"><span class="color_text"> Get In Touch</span><br>
                  With Us </div>
              <div class="text_para">
                  <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua</p> -->
              </div>
            </div>
            <div class="col-lg-6 col-6 text-white mobileRes">
           
              <div class="girl_img">
                  <img src="../../../assets/img/zica/Enquiry_pic.png" width="100%" alt="About Us">
              </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 desktopRes">
                <div class="text2"><span class="color_text"> Get In Touch</span><br>
                    With Us </div>
                <div class="text_para">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua</p>
                </div>
                <div class="girl_img">
                    <img src="../../../assets/img/zica/Enquiry_pic.png" alt="About Us">
                </div>
            </div>
            <div class="col-md-6">
                <div class="franchise_form_content">
                    <!-- <app-franchise-form></app-franchise-form> -->
                    <app-amission-course></app-amission-course>
                </div>
            </div>
        </div>
    </div>
    </div>
</section>