<section>
    <div class="box1">
      <div class="bitCard_content">
        <app-sub-menu></app-sub-menu>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="text1">Course Detail</div>
          <div class="text2">UI & UX
          </div>
          <div class="text_para">
            <ul>
                <li>Explore UI/UX design principles to create user-centered digital experiences.</li>
                <li>Master color theory and typography for visually compelling design projects.</li>
                <li>Conduct research and define problems for effective design documentation.</li>
                <li>Develop skills in digital illustrations and advanced wireframing techniques.</li>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <div class="img_box">
            <div class="imgSubBox d-flex">
              <div class="imgContent1">
                <img src="../../../../assets/img/zica/course/ui1.png" class="aniImg1" alt="animation">
              </div>
              <div class="imgContent1">
                <img src="../../../../assets/img/zica/course/ui2.png" class="aniImg2" alt="animation">
              </div>
            </div>
            <div class="imgContent2">
              <img src="../../../../assets/img/zica/course/ui3.png" class="aniImg3" alt="animation">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <!-- 1st month -->
      <div class="row flex-column-reverse flex-lg-row">
        <div class="col-md-12">
          <div class="left_box">
            <div class="text2">PROFESSIONAL PROGRAM IN UI & UX<span class="color_text"> <br>(8 Months)</span>
            </div>
            <div class="sub_text mt-4 text-white">Learn from <span class="sub_text_color">Professionals</span></div>
            <div class="text_para">
              <p>
                This comprehensive program is an immersive 8-month journey into UI & UX design, exploring foundational concepts such as design principles, color theory, and typography. Delve into the laws of UX and learn to define and document problems effectively. Harness digital illustration techniques and collaborative ideation strategies. Master advanced wireframing, usability testing, and design system implementation, culminating in real-world project assignments to solidify skills and expertise.
              </p>
              <div class="sub_text mt-3 text-white">Become a professional artist</div>
              <div class="text_para">
                <p>To become a professional artist, master computer graphics, UI/UX design, and design principles. Study color theory, typography, and UX laws to create visually appealing and user-friendly designs. Develop skills in problem defining, research documentation, digital illustrations, and user interfaces. Engage in collaborative ideation, advanced wireframing, and usability testing. Utilize tools like Photoshop, Illustrator, Adobe XD, and Figma for practical experience. Complete projects to showcase your expertise and creativity.</p>
              </div>
              <div class="sub_text mt-3 text-white">Be Industry Ready</div>
              <div class="text_para">
                <p>Mastering this UI/UX program equips you with essential skills like typography, color theory, and digital illustrations. You’ll learn to use industry-standard tools such as Photoshop, Illustrator, and Figma. With hands-on projects, advanced wireframing, and usability testing, you’ll develop a robust design system, making you industry-ready for a dynamic UI/UX career.</p>
              </div>
              <div class="sub_text mt-4 text-white">Program Highlights:</div>
              <div class="text_para">
                <ul>
                    <li>Learn computer graphics essentials for impactful and creative digital designs.</li>
                    <li>Explore UI/UX design principles to create user-centered digital experiences.</li>
                    <li>Master color theory and typography for visually compelling design projects.</li>
                    <li>Conduct research and define problems for effective design documentation.</li>
                    <li>Develop skills in digital illustrations and advanced wireframing techniques.</li>
                    <li>Utilize industry-standard software for collaborative ideation and usability testing.</li>
                  </ul>
              </div>
              <div class="accordion mt-2" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                      aria-expanded="true" aria-controls="collapseOne">
                      MODULE I
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div class="row">
                        <div class="col-5">
                          <ul>
                            <li>Computer Graphics</li>
                            <li>Introduction to UI/ UX design</li>
                            <li>Introduction to Design Principles</li>
                            <li>Colour Theory</li>
                            <li>Typography</li>
                            <li>Laws of UX design</li>
                            <li>Problem Defining Documentation</li>
                          </ul>
                        </div>
                        <div class="col-5">
                            <ul>
                                <li>Defining the Research Data</li>
                                <li>Digital Illustrations</li>
                                <li>User Interface</li>
                                <li>Collaborative Ideation</li>
                                <li>Advance Wireframing and usability testing</li>
                                <li>Design System and UI Design Advance</li>
                                <li>Assignment / Project</li>
                            </ul>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="sub_text_color sub_text mt-4">Career Avenues</div>
              <p>After successful completion of the said program, a student is eligible for following job categories.</p>
              <div class="row mt-3">
                <div class="col-6">
                  <ul>
                    <li>UI/UX Designer</li>
                    <li>Product Designer</li>
                    <li>Interaction Designe</li>
                    <li>Visual Designer</li>
                    <li>UX Researcher</li>
                    <li>Information Architect</li>
                    <li>Front-end Developer</li>
                  </ul>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
  <!-- 2nd month -->
  <!-- <div class="row flex-column-reverse flex-lg-row mt-5">
    <div class="col-md-12">
      <div class="left_box">
        <div class="text2">PDP In 3D Animation Visual Effects <span class="color_text"> (27 Months)</span>
        </div>
        <div class="sub_text mt-4 text-white">Learn from <span class="sub_text_color">Professionals</span></div>
        <div class="text_para">
          <p>
            This program has been developed based on the current requirement of 2D Animators in the world. The prerequisite for the program is good drawing skills. Student will learn all the aspects of 2D Animation film making from Pre-production, Production and Post production. Student will initially learn fundamental of Art and design by manual drawing and then they will learn character design, background painting, character animation, audio and video editing by using advance digital tools.
          </p>
          <div class="sub_text mt-3 text-white">Become a professional artist</div>
          <div class="text_para">
            <p>A well laid out assessment procedure ensures the students’ acceptability as distinguished professionals in the world of Animation. ZICA’s placement cell provides placement assistance to all ZICA students across the country. ZICA training academy is equipped with high-end animation tools, 2D animation facilities, 3D software, Visual FX Labs and Imaging Technologies.</p>
          </div>
          <div class="sub_text mt-3 text-white">Be Industry Ready</div>
          <div class="text_para">
            <p>Students walk away with a rich portfolio comprising of best Digital paint, Matte paint, Character design, 2D digital Animation portfolio. This portfolio will be a gateway to join the animation industry.</p>
          </div>
          <div class="sub_text mt-4 text-white">Program Highlights:</div>
          <div class="text_para">
            <ul>
              <li>Increase design and visualization skills</li>
              <li>Learn complete 2D Production Pipeline</li>
              <li>Continuous evaluation through competitions and activities</li>
              <li>Placement support</li>
              </ul>
          </div>
          <div class="accordion mt-2" id="accordionExample01">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne1">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo01"
                  aria-expanded="true" aria-controls="collapseTwo01">
                  Module 01: Fundamentals Of Art, Design & Computer Graphics (6 Months)
                </button>
              </h2>
              <div id="collapseTwo01" class="accordion-collapse collapse" aria-labelledby="headingOne1"
                data-bs-parent="#accordionExample01">
                <div class="accordion-body">
                  <div class="row">
                    <div class="col-md-6">
                      <ul>
                        <li>Preface</li>
                        <li>Sketching</li>
                        <li>Human Figure Study</li>
                        <li>Animal Study</li>
                        <li>Perspective Study</li>
                        <li>Colour Theory</li>
                        <li>Story</li>
                       
                      </ul>
                    </div>
                    <div class="col-md-6">
                      <ul>
                        <li>Master Layout Design</li>
                        <li>Character Design</li>
                        <li>Digital illustration</li>
                        <li>Storyboard</li>
                        <li>Computer Graphics</li>
                        <li>Digital 2D Animation</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo1">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo02" aria-expanded="false" aria-controls="collapseTwo02">
                  Module 02:3D Digital Art (6 Months)
                </button>
              </h2>
              <div id="collapseTwo02" class="accordion-collapse collapse" aria-labelledby="headingTwo1"
                data-bs-parent="#accordionExample01">
                <div class="accordion-body">
                  <div class="row">
                    <div class="col-md-6">
                      <ul>
                        <li>Video & Audio Editing</li> 
                        <li>Stopmotion</li>
                        <li>3D Modelling</li>
                        
                      </ul>
                    </div>
                    <div class="col-md-6">
                      <ul>
                        <li>Advanced Texturing</li> 
                        <li>Lighting</li>
                        <li>Rigging</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree1">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo03" aria-expanded="false" aria-controls="collapseTwo03">
                  Module 03: 3D Character Animation & Dynamics (6 Months)
                </button>
              </h2>
              <div id="collapseTwo03" class="accordion-collapse collapse" aria-labelledby="headingThree1"
                data-bs-parent="#accordionExample01">
                <div class="accordion-body">
                  <div class="row">
                    <div class="col-md-6">
                      <ul>
                        <li>3D Character Animation</li>
                        <li>Dynamics</li>
                       
                      </ul>
                    </div>
                    <div class="col-md-6">
                      <ul>
                        <li>Tech Animation</li>
                        <li>Liquid Simulation</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree2">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo04" aria-expanded="false" aria-controls="collapseTwo04">
                  Module 04: Visual Effects, Elective Specialization (6 Months)
                </button>
              </h2>
              <div id="collapseTwo04" class="accordion-collapse collapse" aria-labelledby="headingThree2"
                data-bs-parent="#accordionExample01">
                <div class="accordion-body">
                  <div class="row">
                    <div class="col-md-6">
                      <ul>
                          <li>Digital Compositing - 1</li>
                          <li>Element 3D</li>
                          <li>Digital Compositing - 2</li>
                          
                      </ul>
                    </div>
                    <div class="col-md-6">
                      <ul>
                        <li>Digital Compositing – 3</li>
                        <li>Elective specialization (2 Months)</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree4">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo05" aria-expanded="false" aria-controls="collapseTwo05">
                  Module 05:  Intro To AR & VR & Portfolio Development (3 Months)
                </button>
              </h2>
              <div id="collapseTwo05" class="accordion-collapse collapse" aria-labelledby="headingThree4"
                data-bs-parent="#accordionExample01">
                <div class="accordion-body">
                  <div class="row">
                    <div class="col-4">
                      <ul>
                        <li>Introduction to AR & VR through ZICA APP</li>
                        <li>Portfolio Development</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="sub_text_color sub_text mt-4">Career Avenues</div>
          <p>After successful completion of the said program, a student is eligible for following job categories.</p>
          <div class="row mt-3">
            <div class="col-6">
              <ul>
                <li>Modeling Artist</li>
                <li>Texturing Artist</li>
                <li>Lighting Artist</li>
                <li>Rigger</li>
                <li>Animator</li>
                <li>FX / Simulation Artist</li>
              </ul>
            </div>
            <div class="col-6">
              <ul>
                <li>Render Wrangler</li>
                <li>Rotoscope Artist</li>
                <li>Prep / Paint Artist</li>
                <li>Clean-up Artist</li>
                <li>Rotomation Artist</li>
                <li>Compositor</li>
                <li>VFX Artist</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  
  <!-- 3d month -->
  
  <!-- <div class="row flex-column-reverse flex-lg-row mt-5">
    <div class="col-md-12">
      <div class="left_box">
        <div class="text2">Professional Program In 3D Animation <span class="color_text"> (12 Months)</span>
        </div>
        <div class="sub_text mt-4 text-white">Learn from <span class="sub_text_color">Professionals</span></div>
        <div class="text_para">
          <p>
            This program has been developed to provide specialized knowledge in 3D Animation. It covers Fundamentals of Art and Design after which students are exposed to the detailed 3D module. At the end of the program, there will be three elective specializations such as Modeling & Texturing, Lighting and Animation.  </p>
          <div class="sub_text mt-3 text-white">Become a professional artist</div>
          <div class="text_para">
            <p>A well laid out assessment procedure ensures the students’ acceptability as distinguished professionals in the world of Animation. ZICA’s placement cell provides placement assistance to all ZICA students across the country. ZICA training academy is equipped with high-end animation tools, 2D animation facilities, 3D software, Visual FX Labs and Imaging Technologies.</p>
          </div>
          <div class="sub_text mt-3 text-white">Be Industry Ready</div>
          <div class="text_para">
            <p>Students walk away with a rich portfolio comprising of best 3D art, Lighting or Animation output. This portfolio will be a gateway to join the animation industry.</p>
          </div>
          <div class="sub_text mt-4 text-white">Program Highlights:</div>
          <div class="text_para">
            <ul>
                <li>Affiliated with MESC</li>
                <li>Increase design and visualization skills</li>
                <li>Learn complete Production Pipeline</li>
                <li>Continuous evaluation through competitions and activities</li>
                <li>Introduction of Augmented Reality & Virtual Reality</li>
                <li>Placement support</li>
              </ul>
          </div>
          <div class="accordion mt-2" id="accordionExample011">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne11">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo011"
                  aria-expanded="true" aria-controls="collapseTwo011">
                  Module 01: Fundamentals Of Art, Design & Computer Graphics (2 Months)
                </button>
              </h2>
              <div id="collapseTwo011" class="accordion-collapse collapse" aria-labelledby="headingOne11"
                data-bs-parent="#accordionExample011">
                <div class="accordion-body">
                  <div class="row">
                    <div class="col-md-6">
                      <ul>
                        <li>History of Animation</li>
                        <li>Fundamental of Art & Design</li>
                        <li>Principle of Design</li>
                       
                       
                      </ul>
                    </div>
                    <div class="col-md-6">
                      <ul>
                        <li>Still Life</li>
                        <li>Colour Theory</li>
                        <li>Computer Graphics</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo11">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo021" aria-expanded="false" aria-controls="collapseTwo021">
                  Module 02:3d Animation (6 Months)
                </button>
              </h2>
              <div id="collapseTwo021" class="accordion-collapse collapse" aria-labelledby="headingTwo11"
                data-bs-parent="#accordionExample011">
                <div class="accordion-body">
                  <div class="row">
                    <div class="col-md-6">
                      <ul>
                        <li>3D Character Modeling</li> 
                        <li>Texturing</li>
                        <li>Lighting & Shading</li>
                       
                      </ul>
                    </div>
                    <div class="col-md-6">
                      <ul>
                        <li>Rigging</li>
                        <li>Character Animation</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree11">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo031" aria-expanded="false" aria-controls="collapseTwo031">
                  Module 03:  Compositing (1 Month)
                </button>
              </h2>
              <div id="collapseTwo031" class="accordion-collapse collapse" aria-labelledby="headingThree11"
                data-bs-parent="#accordionExample011">
                <div class="accordion-body">
                  <div class="row">
                    <div class="col-md-6">
                      <ul>
                         <li>Digital Compositing</li>
                       <li>Motion Graphics</li>
                       <li>Rotoscope</li>
                       <li>Keying</li>
                      </ul>
                    </div>
                    <div class="col-md-6">
                      <ul>
                        <li>Colour Correction</li>
                        <li>Camera Projection</li>
                        <li>Video Editing</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree21">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo041" aria-expanded="false" aria-controls="collapseTwo041">
                  Module 04: Specialization - Any One (2 Months)
                </button>
              </h2>
              <div id="collapseTwo041" class="accordion-collapse collapse" aria-labelledby="headingThree21"
                data-bs-parent="#accordionExample011">
                <div class="accordion-body">
                  <div class="row">
                    <div class="col-md-6">
                      <ul>
                        <li>Modeling & Texturing</li>
                        <li>Lighting </li>
                       
                      </ul>
                    </div>
                    <div class="col-md-6">
                      <ul>
                        <li>Animation</li>
                        Module V – Intro To Ar And Vr (1 Month)
                        <li>Introduction to AR & VR through ZICA APP</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="sub_text_color sub_text mt-4">Career Avenues</div>
          <p>After successful completion of the said program, a student is eligible for following job categories.</p>
          <div class="row mt-3">
            <div class="col-6">
              <ul>
                <li>Modeling Artist</li>
                <li>Texturing Artist</li>
                <li>Lighting Artist</li>
                <li>Rigger</li>
                <li>Animator</li>
                <li>FX / Simulation Artist</li>
              </ul>
            </div>
            <div class="col-6">
              <ul>
                <li>Render Wrangler</li>
                <li>Rotoscope Artist</li>
                <li>Prep / Paint Artist</li>
                <li>Clean-up Artist</li>
                <li>Rotomation Artist</li>
                <li>Compositor</li>
                <li>VFX Artist</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
    </div>
  
    <div class="box3">
      <div class="text2 text-center">Our <span class="color_text">Latest Blogs</span></div>
      <div class="text_para text-center">
        <p>ZICA’s Placement Cell Provides Placement Assistance To All ZICA Students Across The Country.</p>
      </div>
      <app-blogs></app-blogs>
    </div>
    <div class="box4 mt-5">
      <div class="row">
        <div class="col-md-6">
          <div class="text2">Frequently Asked<br><span class="color_text">Questions</span>
          </div>
          <div class="text_para">
            <img src="../../../assets/img/zica/faq.png" width="300px" height="300px" alt="animation">
          </div>
        </div>
        <div class="col-md-6">
          <app-faqs></app-faqs>
        </div>
      </div>
    </div>
  
    <div class="box5">
      <div class="row ">
        <div class="col-lg-6 col-6 text-white mobileRes">
  
          <div class="text2"><span class="color_text"> Get In Touch</span><br>
            With Us </div>
          <div class="text_para">
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua</p> -->
          </div>
        </div>
        <div class="col-lg-6 col-6 text-white mobileRes">
  
          <div class="girl_img">
            <img src="../../../assets/img/zica/Enquiry_pic.png" width="100%" alt="animation">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 desktopRes">
          <div class="text2"><span class="color_text"> Get In Touch</span><br>
            With Us </div>
          <div class="text_para">
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua</p> -->
          </div>
          <div class="girl_img">
            <img src="../../../assets/img/zica/Enquiry_pic.png" alt="animation">
          </div>
        </div>
        <div class="col-md-6">
          <div class="franchise_form_content">
            <app-amission-course></app-amission-course>
            <!-- <app-franchise-form></app-franchise-form> -->
          </div>
        </div>
      </div>
    </div>
  </section>