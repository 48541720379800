<section>
    <div class="box1">
      <div class="bitCard_content">
        <app-sub-menu></app-sub-menu>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="text1">Course Detail</div>
          <div class="text2">2D Animation
          </div>
          <div class="text_para">
            <p>This is a comprehensive certificate program that deals with all the important aspects of 2D, 3D & Visual
              Effects. The student begins with learning all the creative development exercises with manual and digital 2D
              animation processes. The second module starts with all the basic elements of 3D digital art which in the
              later module progresses into advanced 3D Digital art and character animation. The remaining modules of the
              program deal with different aspects of Dynamics, tracking,
              and advanced Visual effects which are generally used in production studios.</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="img_box">
            <div class="imgSubBox d-flex">
              <div class="imgContent1">
                <img src="../../../../assets/img/zica/course/animationImg1.png" class="aniImg1" alt="Three-D Animation">
              </div>
              <div class="imgContent1">
                <img src="../../../../assets/img/zica/course/animationImg2.png" class="aniImg2" alt="Three-D Animation">
              </div>
            </div>
            <div class="imgContent2">
              <img src="../../../../assets/img/zica/course/animationImg3.png" class="aniImg3" alt="Three-D Animation">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <!-- 1st month -->
      <div class="row flex-column-reverse flex-lg-row">
        <div class="col-md-12">
          <div class="left_box">
            <div class="text2">Professional Program In 2D Animation<span class="color_text"> (12 Months)</span></div>
            <div class="sub_text mt-4 text-white">Learn from <span class="sub_text_color">Professionals</span></div>
            <div class="text_para">
              <p>
                This program has been developed based on the current requirement of 2D Animators in the world. The prerequisite for the program is good drawing skills. Student will learn all the aspects of 2D Animation film making from Pre-production, Production and Post production. Student will initially learn fundamental of Art and design by manual drawing and then they will learn character design, background painting, character animation, audio and video editing by using advance digital tools.
              </p>
              <div class="sub_text mt-3 text-white">Become a professional artist</div>
              <div class="text_para">
                <p>A well laid out assessment procedure ensures the students’ acceptability as distinguished professionals in the world of Animation. ZICA’s placement cell provides placement assistance to all ZICA students across the country. ZICA training academy is equipped with high-end animation tools, 2D animation facilities, 3D software, Visual FX Labs and Imaging Technologies.</p>
              </div>
              <div class="sub_text mt-3 text-white">Be Industry Ready</div>
              <div class="text_para">
                <p>Students walk away with a rich portfolio comprising of best Digital paint, Matte paint, Character design, 2D digital Animation portfolio. This portfolio will be a gateway to join the animation industry.</p>
              </div>
              <div class="sub_text mt-4 text-white">Program Highlights:</div>
              <div class="text_para">
                <ul>
                  <li>Increase design and visualization skills</li>
                  <li>Learn complete 2D Production Pipeline</li>
                  <li>Continuous evaluation through competitions and activities</li>
                  <li>Placement support</li>
                  </ul>
              </div>
              <div class="accordion mt-2" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                      aria-expanded="true" aria-controls="collapseOne">
                      Module 01: Fundamentals Of Art, Design & Computer Graphics (6 Months)
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div class="row">
                        <div class="col-4">
                          <ul>
                            <li>Preface</li>
                            <li>Principles of Design</li>
                            <li>Sketching</li>
                            <li>Still life</li>
                            <li>Cartoon Drawing</li>
                            <li>Digital illustration</li>
                            
                          </ul>
                        </div>
                        <div class="col-4">
                          <ul>
                            <li>Computer Graphics</li>
                            <li>Human Figure Study</li>
                            <li>Anatomy study</li>
                             <li>Animal Study (Skeleton)</li>
                            <li>Perspective Study</li>
                            
                          </ul>
                        </div>
                        <div class="col-4">
                          <ul>
                            <li>Colour Theory</li>
                            <li>Story</li>
                            <li>Character design</li>
                            <li>Master Layout Design</li>
                            <li>Layout & Background</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Module 02: 2D Digital Animation (6 Months)
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div class="row">
                        <div class="col-4">
                          <ul>
                            <li>Video Editing</li>
                            <li>Audio Editing</li>
                            <li>Storyboard</li>
                            <li>Animatic</li>
                           
                          </ul>
                        </div>
                        <div class="col-4">
                          <ul>
                            <li>Stopmotion</li>
                            <li>Digital 2D Animation</li>
                            <li>Acting for Animation</li>
                            <li>Bio-Mechanics / Organic Animation (Digital)</li>
                            <li>Special effects (Digital)</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="sub_text_color sub_text mt-4">Career Avenues</div>
              <p>After successful completion of the said program, a student is eligible for following job categories.</p>
              <div class="row mt-3">
                <div class="col-6">
                  <ul>
                <li>Pre-production artist</li>
                    <li>Character designer</li>
                    <li>Storyboard artist</li>
                    <li>Layout / background artist</li>
                  </ul>
                </div>
                <div class="col-6">
                  <ul>
                    <li>Art director</li>
                    <li>2D Animator </li>
                    <li>Visualizers</li>
                  </ul>
                </div>
              </div>
             
            </div>
          </div>
        </div>
        <!-- <div class="col-md-4">
          <div class="list_box">
            <div class="list">
              <div class="letf_text sub_text_color">Level</div>
              <div class="right_text">Intermediate</div>
            </div>
            <div class="list">
              <div class="letf_text sub_text_color">Duration</div>
              <div class="right_text">6 Months</div>
            </div>
            <div class="list">
              <div class="letf_text sub_text_color">Eligibility</div>
              <div class="right_text">10th Pass</div>
            </div>
            <div class="list">
              <div class="letf_text sub_text_color">Avg. Package</div>
              <div class="right_text">12LPA</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  
    <div class="box3">
      <div class="text2 text-center">Our <span class="color_text">Latest Blogs</span></div>
      <div class="text_para text-center">
        <p>ZICA’s Placement Cell Provides Placement Assistance To All ZICA Students Across The Country. ZICA
          Training Academy Is Equipped With High-End Animation Tools, 2D Animation Facilities, 3D Software,
          Visual FX Labs And Imaging Technologies.</p>
      </div>
      <app-blogs></app-blogs>
    </div>
    <div class="box4 mt-5">
      <div class="row">
        <div class="col-md-6">
          <div class="text2">Frequently Asked<br><span class="color_text">Questions</span>
          </div>
          <div class="text_para">
           <img src="../../../assets/img/zica/faq.png" width="300px" height="300px" alt="Three-D Animation">
          </div>
        </div>
        <div class="col-md-6">
          <app-faqs></app-faqs>
        </div>
      </div>
    </div>
  
    <div class="box5">
      <div class="row ">
        <div class="col-lg-6 col-6 text-white mobileRes">
  
          <div class="text2"><span class="color_text"> Get In Touch</span><br>
            With Us </div>
          <div class="text_para">
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua</p> -->
          </div>
        </div>
        <div class="col-lg-6 col-6 text-white mobileRes">
  
          <div class="girl_img">
            <img src="../../../assets/img/zica/Enquiry_pic.png" width="100%" alt="Three-D Animation">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 desktopRes">
          <div class="text2"><span class="color_text"> Get In Touch</span><br>
            With Us </div>
          <div class="text_para">
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua</p> -->
          </div>
          <div class="girl_img">
            <img src="../../../assets/img/zica/Enquiry_pic.png" alt="Three-D Animation">
          </div>
        </div>
        <div class="col-md-6">
          <div class="franchise_form_content">
            <!-- <app-franchise-form></app-franchise-form> -->
            <app-amission-course></app-amission-course>
          </div>
        </div>
      </div>
    </div>
  </section>