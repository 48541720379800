<section>
    <div class="whiteHeader_dark desktopNav" id="whiteHeader_dark">
        <nav class="nav navbar navbar-expand-lg">
            <div class="container-fluid">
                <div class="navbar-brand d-flex">
                    <div class="">
                        <a href="">
                            <img src="../../../../assets/img/zica/ZICA_Logo_finle.png" class="zica_logo" alt=""
                                routerLink="">
                        </a>
                    </div>
                </div>
                <div class="menu_Img" data-bs-toggle="collapse" data-bs-target="#navbarNav" id="menuClose">
                    <div class="menu_icon"></div>
                </div>
                <div class="collapse navbar-collapse " id="navbarNav">
                    <ul class="navbar-nav ul_content">

                        <li class="nav-item">
                            <a class="nav-link" href="partner_page#Home">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="partner_page#About">About</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="partner_page#WhyZICA">Why ZICA?</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="partner_page#AwardsRecognition">Awards & Recognition</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="partner_page#Testimonial">Testimonial</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="partner_page#ContactUs">Contact Us</a>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>
    </div>

    <div class="whiteHeader_dark mobile_nav" id="whiteHeader_dark">
        <nav class="nav navbar navbar-expand-lg">
            <div class="container-fluid">
                <div class="navbar-brand d-flex">
                    <div class="">
                        <img src="../../../../assets/img/zica/ZICA_Logo_finle.png" class="zica_logo" alt=""
                            routerLink="">
                    </div>
                    <div class="">
                        <img src="../../../../assets/img/zica/year_logo.png" class="header_logo2" alt="">
                    </div>
                </div>
                <div class="menu_Img" data-bs-toggle="collapse" data-bs-target="#navbarNav" id="menuClose">
                    <div class="menu_icon"></div>
                </div>
                <div class="collapse navbar-collapse " id="navbarNav">
                    <ul class="navbar-nav ul_content">

                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="partner_page#Home"
                                (click)="clickMenu('Home')">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="partner_page#About"
                                (click)="clickMenu('About')">About</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="partner_page#WhyZICA"
                                (click)="clickMenu('WhyZICA')">Why
                                ZICA?</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="partner_page#AwardsRecognition"
                                (click)="clickMenu('AwardsRecognition')">Awards & Recognition</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="partner_page#Testimonial"
                                (click)="clickMenu('Testimonial')">Testimonial</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="partner_page#ContactUs"
                                (click)="clickMenu('ContactUs')">Contact Us</a>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>
    </div>
</section>
<section>
    <div class="box1" id="Home">
        <div class="bg_banner">
            <div class="row">
                <div class="col-md-8 paddin_zero">
                    <img src="../../../assets/img/zica/banner/partner_page_banner.png" class="img-fluid desktop_banner"
                        width="100%" alt="">
                        <img src="../../../assets/img/zica/banner/mobile_banner.png" class="img-fluid mobile_banner"
                        width="100%" alt="">
                </div>
                <div class="col-md-4 paddin_zero">
                    <div class="bg_form">
                        <div class="admissionForm" #admissionFormId>
                            <div class="form">
                                <form action="" [formGroup]="admissionForm">
                                    <div class="row">
                                        <div class="col-md-12 mt-1">
                                            <label for="">Name<sup>*</sup></label>
                                            <input type="text" class="form-control mt-1" id="userName" formControlName="fname"
                                                [ngClass]="{ 'is-invalid': submitted && f.fname.errors }">
                                            <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                                                <span class="text-danger msg_txt"
                                                    *ngIf="(f.fname.touched || submitted) && f.fname.errors?.required">
                                                    Name is required
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-1">
                                            <label for="">Email Id<sup>*</sup></label>
                                            <input type="text" class="form-control mt-1" formControlName="email"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <span class="text-danger msg_txt"
                                                    *ngIf="(f.email.touched || submitted) && f.email.errors?.required">
                                                    Email is required
                                                </span>
                                                <span class="text-danger msg_txt"
                                                    *ngIf="f.email.touched && f.email.errors?.email">
                                                    Enter a valid email address
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-1">
                                            <label for="">Mobile No<sup>*</sup></label>
                                            <input type="text" class="form-control mt-2" formControlName="mobile"
                                                (keyup)="getMobileNO();" (keypress)="onlyNumberKey($event)"
                                                [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                                            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                                <span *ngIf="f.mobile.errors?.required">Mobile is required.</span>
                                                <span *ngIf="f.mobile.errors?.pattern">Please, Enter 10 digit
                                                    Mobile.</span>
                                            </div>
                                            <div *ngIf="otpInput" class="validMsg">OTP sent successfully</div>
                                        </div>
                                        <div class="col-md-12 mt-1" *ngIf="otpInput">
                                            <div class="form-group">
                                                <label for="">OTP<sup>*</sup></label>
                                                <input type="text" class="form-control" (keyup)="verifyOTP();"
                                                    formControlName="otp" (keypress)="onlyNumberKey($event)"
                                                    [ngClass]="{ 'is-invalid': submitted && f.otp.errors }">
                                                <div *ngIf="submitted && f.otp.errors" class="invalid-feedback">
                                                    <span *ngIf="f.otp.errors?.required">OTP is required.</span>
                                                </div>
                                                <div *ngIf="otp_ValidMsg" class="validMsg">otp verified successfully
                                                </div>
                                                <div *ngIf="otp_inValidMsg" class="inValidMsg">please enter valid otp
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-1">
                                            <label for="">Minimum Budget to be Invested in Lakhs<sup>*</sup></label>
                                            <input type="text" class="form-control mt-1" formControlName="budget"
                                                [ngClass]="{ 'is-invalid': submitted && f.budget.errors }"
                                                (keypress)="onlyNumberKey($event)">
                                            <div *ngIf="submitted && f.budget.errors" class="invalid-feedback">
                                                <span class="text-danger msg_txt"
                                                    *ngIf="(f.budget.touched || submitted) && f.budget.errors?.required">
                                                    Budget is required
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-1">
                                            <label for="">Pin Code<sup>*</sup></label>
                                            <input type="text" class="form-control" formControlName="pinCode"
                                                [ngClass]="{ 'is-invalid': submitted && f.pinCode.errors }">
                                            <div *ngIf="submitted && f.pinCode.errors" class="invalid-feedback">
                                                <span *ngIf="f.pinCode.errors?.required">Pin code is required.</span>
                                                <span *ngIf="f.pinCode.errors?.pattern">Please, Enter 6 digit
                                                    pincode.</span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-1">
                                            <label for="">State<sup>*</sup></label>
                                            <select name="" id="" class="form-control" formControlName="state"
                                                (change)="selectState($event)"
                                                [ngClass]="{ 'is-invalid': submitted && f.state.errors }">
                                                <option value="">State/District</option>
                                                <option value="{{state.State_Name}}" *ngFor="let state of stateList">
                                                    {{state.State_Name}}</option>
                                            </select>
                                            <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                                                <span class="text-danger msg_txt"
                                                    *ngIf="(f.state.touched || submitted) && f.state.errors?.required">
                                                    State/District is required
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-1">
                                            <label for="">City<sup>*</sup></label>
                                            <select name="" id="" class="form-control" formControlName="city"
                                                (change)="selectCity($event)"
                                                [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
                                                <option value=null>City</option>
                                                <option value="{{city.City_Name}}" *ngFor="let city of cityList">
                                                    {{city.City_Name}}</option>
                                            </select>
                                            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                                <span class="text-danger msg_txt"
                                                    *ngIf="(f.city.touched || submitted) && f.city.errors?.required">
                                                    City is required
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="sub_btn d-flex justify-content-center mt-4 py-1">
                                    <button class="btn submit_btn" (click)="validationForm();">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="box3 scroll_padding" id="About">
        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-md-6">
                <div class="lagacyImg_content scrollPadding">
                    <div class="firstImgCOntent d-flex">
                        <div class="firstImgBox"><img src="../../../assets/img/zica/parent_1.png" class="aniImg1"
                                alt="Parent Page"></div>
                        <div class="firstImgBox"><img src="../../../assets/img/zica/parent_2.png"
                                class="secondImg aniImg2" alt="Parent Page"></div>
                    </div>
                    <div class="secontImgCOntent">
                        <div class="firstImgBox">
                            <img src="../../../assets/img/zica/parent_3.png" alt="Parent Page" class="aniImg3">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="text2 text-left">About <span class="color_text">ZICA</span></div>
                <div class="text_para mt-4">
                    <p>In the year 1995, the Chairman Dr. Subash Candra started Zee Institute of Creative Art (ZICA),
                        the nation's first full-fledged Classical and Digital Animation Training Academy that trains
                        youth in classical 2D and modern 3D animation. The past 28 years ZICA has nurtured some of the
                        brightest talents in the world of animation. ZICA adopted a novel training style and is focused
                        entirely on creating a stimulating environment for its students.</p>
                    <p>ZICA, with its state-of-the-art infrastructure, gives practical training in 2D Animation, 3D
                        Animation, Visual Effects & Gaming, covering the stages of visualization, Pre-production,
                        production and post-production. At ZICA. A lot of emphasis is laid on the principles of art and
                        design and fundamentals of classical animation, while extracting the same knowledge to computer
                        based Digital animation.</p>
                    <p>Today, ZICA centers in prominent cities like Mumbai, Pune, Bangalore, Noida, Lucknow,
                        Bhubaneswar, Indore, and more, stand out as the preferred choice for young Indians aspiring to
                        transform their passion into a profession.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="box4 scroll_padding" id="WhyZICA">
        <div class="why_zica_content">
            <div class="row">
                <div class="col-md-6">
                    <div class="text2 text-left">Why <span class="color_text">ZICA?</span></div>
                    <div class="list_content mt-4">
                        <button class="btn btn_color d-flex justify-content-start align-items-center">
                            <div class="left_icon"><img src="../../../assets/img/zica/icon/marketing.png" width="45px"
                                    height="45px" alt="Home"></div>
                            <span class="mx-3"> Marketing & Admissions Support </span>
                        </button>
                        <button class="btn btn_color d-flex justify-content-start align-items-center mt-2">
                            <div class="left_icon"><img src="../../../assets/img/zica/icon/proven.png" width="45px"
                                    height="45px" alt="Home"></div>
                            <span class="mx-3"> Proven Business Model </span>
                        </button>
                        <button class="btn btn_color d-flex justify-content-start align-items-center mt-2">
                            <div class="left_icon"><img src="../../../assets/img/zica/icon/market.png" width="45px"
                                    height="45px" alt="Home"></div>
                            <span class="mx-3"> Market Leader </span>
                        </button>
                        <button class="btn btn_color d-flex justify-content-start align-items-center mt-2">
                            <div class="left_icon"><img src="../../../assets/img/zica/icon/placement.png" width="45px"
                                    height="45px" alt="Home"></div>
                            <span class="mx-3"> Placement Assistance </span>
                        </button>
                        <button class="btn btn_color d-flex justify-content-start align-items-center mt-2">
                            <div class="left_icon"><img src="../../../assets/img/zica/icon/curriculum.png" width="45px"
                                    height="45px" alt="Home"></div>
                            <span class="mx-3"> Curriculum – Industry Ready Programs </span>
                        </button>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="why_zica_img">
                        <img src="../../../assets/img/zica/banner/why_zica_banner.png" width="100%" alt="Partner Page">
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="box6 mt-5">
        <div class="text2 text-center">Time<span class="color_text">line</span></div>
        <div class="time_line_list mt-4 d-none d-sm-block">
            <div class="time_btn_list d-flex justify-content-between align-items-center">
                <div class="time_btn">
                    <div class=""><button class="btn btn_bg_color">DAY 0</button></div>
                    <span>DOCUMENTATIONS SUBMISSION</span>
                </div>
                <div class="time_btn">
                    <div class=""><button class="btn btn_bg_color">DAY 14</button></div>
                    <span>PROPERTY INSPECTION</span>
                </div>
                <div class="time_btn">
                    <div class=""><button class="btn btn_bg_color">DAY 60</button></div>
                    <span>CENTRE READY</span>
                </div>
                <div class="time_btn">
                    <div class=""><button class="btn btn_bg_color">DAY 90</button></div>
                    <span>MARKETING AND CENTRE LAUNCH</span>
                </div>
            </div>
            <div class="time_img_content py-4">
                <img src="../../../assets/img/zica/timeLine_img.png" width="100%" alt="Time Line">
            </div>
            <div class="time_btn_list d-flex justify-content-evenly align-items-center">
                <div class="time_btn">
                    <div class=""><button class="btn btn_bg_color">DAY 7</button></div>
                    <span>AGREEMENT SIGNUP</span>
                </div>
                <div class="time_btn">
                    <div class=""><button class="btn btn_bg_color">DAY 21</button></div>
                    <span>PROPERTY FINALIZATION</span>
                </div>
                <div class="time_btn">
                    <div class=""><button class="btn btn_bg_color">DAY 75</button></div>
                    <span>MANPOWER RECRUITMENT AND INDUCTION</span>
                </div>
            </div>
        </div>
        <div class="mobile_responsive d-flex justify-content-between align-items-center d-lg-none">

            <div class="time_btn_list">
                <div class="time_btn">
                    <div class=""><button class="btn btn_bg_color">DAY 7</button></div>
                    <span>AGREEMENT SIGNUP</span>
                </div>
                <div class="time_btn">
                    <div class=""><button class="btn btn_bg_color">DAY 21</button></div>
                    <span>PROPERTY FINALIZATION</span>
                </div>
                <div class="time_btn">
                    <div class=""><button class="btn btn_bg_color">DAY 75</button></div>
                    <span>MANPOWER RECRUITMENT AND INDUCTION</span>
                </div>
            </div>
            <div class="img_div">
                <img src="../../../assets/img/zica/timeLine_img_Mobile.png" width="100%" alt="">
            </div>
            <div class="time_btn_list time_btn_list_right">
                <div class="time_btn">
                    <div class=""><button class="btn btn_bg_color">DAY 0</button></div>
                    <span>DOCUMENTATIONS SUBMISSION</span>
                </div>
                <div class="time_btn">
                    <div class=""><button class="btn btn_bg_color">DAY 14</button></div>
                    <span>PROPERTY INSPECTION</span>
                </div>
                <div class="time_btn">
                    <div class=""><button class="btn btn_bg_color">DAY 60</button></div>
                    <span>CENTRE READY</span>
                </div>
                <div class="time_btn">
                    <div class=""><button class="btn btn_bg_color">DAY 90</button></div>
                    <span>MARKETING AND CENTRE LAUNCH</span>
                </div>
            </div>
        </div>

    </div>

    <div class="box7 mt-5" id="AwardsRecognition">
        <div class="award_rec_content">
            <div class="row">
                <div class="col-md-6">
                    <div class="left_box">
                        <div class="text2">Awards & <br><span class="color_text">Recognition</span>
                        </div>
                        <div class="video_content dashed_border mt-2">
                            <nav>
                                <div class="nav nav-tabs d-flex justify-content-center" id="nav-tab" role="tablist">
                                    <button class="nav-link active" id="nav-zica-tab" data-bs-toggle="tab"
                                        data-bs-target="#nav-zica" type="button" role="tab" aria-controls="nav-zica"
                                        aria-selected="true">ZICA</button>
                                    <button class="nav-link" id="nav-zill-tab" data-bs-toggle="tab"
                                        data-bs-target="#nav-zill" type="button" role="tab" aria-controls="nav-zill"
                                        aria-selected="false">ZEE Learn Limited</button>
                                </div>
                            </nav>
                            <div class="tab-content p-3" id="nav-tabContent">
                                <div class="tab-pane fade active show" id="nav-zica" role="tabpanel"
                                    aria-labelledby="nav-zica-tab">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="text_para">
                                                <ul>
                                                    <li>Recognized for its contribution to AVGC - Education at EduSpark
                                                        Summit and Awards 2022</li>
                                                    <li>Acknowledged as MECAT partner by the MESC, 2022</li>
                                                    <li>Recognized as a leading Vocational Institute for Skill
                                                        Development at the 18th World Education Summit 2021</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="nav-zill" role="tabpanel" aria-labelledby="nav-zill-tab">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="text_para">
                                                <ul>
                                                    <li>Zee Learn awarded Dream Companies To Work For - Education Sector
                                                        - March 2022</li>
                                                    <li>Premier Franchise to Watch for in 2019 by Insights Success
                                                        magazine, Oct 2019</li>
                                                    <li>Franchisor of the year 2018 by Franchise India</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="right_box">
                        <div class="img_box">
                            <img src="../../../assets/img/zica/awards1.png" width="100%" alt="Awards And Recognition">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="box8 mt-5">
        <div class="row">
            <div class="col-md-6">
                <div class="text1">Why</div>
                <div class="text2"><span class="color_text">Animation & VFX</span> Business?
                </div>
                <div class="text_para">
                    <ul>
                        <li><span class="color_text font_size"><b>Artificial Intelligence</b></span> is expected to
                            influence the <span class="color_text font_size"><b> gaming sector,</b></span>
                            with consumers demanding an enhanced gaming experience
                            that will present an opportunity for <span class="color_text font_size"><b>key players to
                                    invest.</b></span>
                        </li>
                        <li><span class="color_text font_size"><b>Virtual reality (VR)</b></span> is the fastest-growing
                            E&M segment, albeit
                            from a small base. Its revenues surged by<span class="color_text font_size"><b> 31.7% in
                                    2020 to
                                    US$1.8 Bn</b></span> and are projected to sustain a <span
                                class="color_text font_size"><b>CAGR of 30%+ </b></span> over the
                            next five years to reach <span class="color_text font_size"><b> US$6.9 Bn business in
                                    2025.</b></span></li>
                        <li>Entertainment and Media Market to Expand at <span class="color_text font_size"><b>8.9% CAGR
                                </b></span> to
                            Reach <span class="color_text font_size"><b> USD 2.6 Trillion by 2025</b> </span></li>
                        <li>
                            By 2025, the number of connected smart televisions are
                            expected to reach <span class="color_text font_size"><b>40-50 million.</b></span> 30% of the
                            content viewed on
                            these screens will be gaming, social media, short video and
                            content items produced exclusively for this audience by
                            television, print and radio brands.
                        </li>
                        <li>
                            OTT video services market (video-on-demand and live) in India
                            is likely to post a <span class="color_text font_size"><b> CAGP of 29.52% to mach US$ 5.12
                                    billion by
                                    FY26,</b></span> driven by rapid developments in online platforms and
                            increased demand for quality content among users.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="img_box">
                    <div class="imgSubBox d-flex">
                        <div class="imgContent1">
                            <img src="../../../../assets/img/zica/course/visual1.png" class="aniImg1"
                                alt="Three-D Animation">
                        </div>
                        <div class="imgContent1">
                            <img src="../../../../assets/img/zica/course/visual2.png" class="aniImg2"
                                alt="Three-D Animation">
                        </div>
                    </div>
                    <div class="imgContent2">
                        <img src="../../../../assets/img/zica/course/visual3.png" class="aniImg3"
                            alt="Three-D Animation">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="box9" id="FAQs">
        <div class="row scrollPadding">
            <div class="col-md-6">
                <div class="text2">Frequently Asked<br><span class="color_text">Questions</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="accordion_box">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <div>
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button1" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Are drawing skills compulsory for doing the Animation program?
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>For 2D animation drawing skills is a must. If you are more interested in the
                                            3D animation, then you will suffice without drawing.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div>
                                <h2 class="accordion-header" id="headingOne1">
                                    <button class="accordion-button1" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne1" aria-expanded="true"
                                        aria-controls="collapseOne1">
                                        Which course I should prefer after Grade 12th?
                                    </button>
                                </h2>
                                <div id="collapseOne1" class="accordion-collapse collapse" aria-labelledby="headingOne1"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>After 12th you should opt for a career-oriented program. The duration of the
                                            program should be 2 years or more.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <div>
                                <h2 class="accordion-header" id="headingOne2">
                                    <button class="accordion-button1" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne2" aria-expanded="true"
                                        aria-controls="collapseOne2">
                                        Are any internship opportunities available? Paid or unpaid?
                                    </button>
                                </h2>
                                <div id="collapseOne2" class="accordion-collapse collapse" aria-labelledby="headingOne2"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>The internship opportunity is optional and provided from time to time.
                                            Internships are with a basic stipend. Most of the students do not opt for an
                                            internship as production studios hire them on a full-time basis after the
                                            course is completed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <div>
                                <h2 class="accordion-header" id="headingOne3">
                                    <button class="accordion-button1" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne3" aria-expanded="true"
                                        aria-controls="collapseOne3">
                                        Any hidden charges or extra charges apart from fees?
                                    </button>
                                </h2>
                                <div id="collapseOne3" class="accordion-collapse collapse" aria-labelledby="headingOne3"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>There are no hidden charges from the institute apart from the fees. You might
                                            have to purchase basic stationaries (Pencil, drawing notebook etc) for your
                                            personal practice purpose. </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <div>
                                <h2 class="accordion-header" id="headingOne4">
                                    <button class="accordion-button1" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne4" aria-expanded="true"
                                        aria-controls="collapseOne4">
                                        Can we get the Demo class session?
                                    </button>
                                </h2>
                                <div id="collapseOne4" class="accordion-collapse collapse" aria-labelledby="headingOne4"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Yes. ZICA center arranges Demo classes for minimum 5 enquiries at a time.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="box10">
        <div class="text2 text-center">ZICA <span class="color_text">Institute</span></div>
        <div class="video_content mt-2">
            <nav>
                <div class="nav nav-tabs mb-3 d-flex justify-content-center" id="nav-tab" role="tablist">
                    <!-- <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home"
                        type="button" role="tab" aria-controls="nav-home" aria-selected="true">Photos</button> -->
                    <!-- <button class="nav-link active" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile"
                        type="button" role="tab" aria-controls="nav-profile" aria-selected="false">ZICA Institute</button> -->
                </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
                <!-- <div class="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div class="imggallery">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-6 d-flex justify-content-center">
                                <div class="video_content">
                                    <div class="imgList">
                                        <a data-fancybox href="../../../assets/img/zica/award_img.jpg">
                                            <img src="../../../assets/img/zica/award_img.jpg" class="stdImg">
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-6 d-flex justify-content-center">
                                <div class="video_content">
                                    <div class="imgList">
                                        <a data-fancybox href="../../../assets/img/zica/award_img.jpg">
                                            <img src="../../../assets/img/zica/award_img.jpg" class="stdImg">
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-6 d-flex justify-content-center">
                                <div class="video_content">
                                    <div class="imgList">
                                        <a data-fancybox href="../../../assets/img/zica/award_img.jpg">
                                            <img src="../../../assets/img/zica/award_img.jpg" class="stdImg">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="tab-pane fade active show" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="full_video">
                                <iframe width="100%" height="400px" src="https://www.youtube.com/embed/QPj4sIVHtOI"
                                    title="Partner Page" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="multi_video">
                                <iframe width="100%" height="190px" src="https://www.youtube.com/embed/eFg7nQmMoo8"
                                    title="Partner Page" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                            <div class="multi_video mt-3">
                                <iframe width="100%" height="190px" src="https://www.youtube.com/embed/ZDtlFINVj0g"
                                    title="Partner Page" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="video_content scroll_padding" id="Testimonial">
            <div class="text2 text-center">ZICA Partner <span class="color_text">Testimonial</span></div>
            <div class="row">
                <div class="col-md-6">
                    <div class="full_video mt-3">
                        <iframe class="video_frem" src="https://www.youtube.com/embed/U2Aox_Ga9wU" title="partner page"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="full_video mt-3">
                        <iframe class="video_frem" src="https://www.youtube.com/embed/ZUrx3QR1-e8" title="partner page"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="video_content scroll_padding" id="ContactUs">
            <div class="midea_list">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-6">
                        <div class="midea_box">
                            <div class="midea_img">
                                <img src="../../../assets/img/zica/icon/phone_img.png" width="50px" height="50px"
                                    alt="">
                            </div>
                            <div class="midea_phone">Phone</div>
                            <div class="midea_text" data-toggle="tooltip" data-placement="bottom"
                                title="+91 91520 10880">+91 9152010855</div>
                            <!-- <div class="midea_text" data-toggle="tooltip" data-placement="bottom"
                                title="+91 91520 10880">+91 91520 10880</div> -->
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-6">
                        <div class="midea_box">
                            <div class="midea_img">
                                <img src="../../../assets/img/zica/icon/email_img.png" width="50px" height="50px"
                                    alt="">
                            </div>
                            <div class="midea_phone">Email</div>
                            <div class="midea_text">franchise@zica.org</div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="midea_box">
                            <div class="midea_img">
                                <img src="../../../assets/img/zica/icon/watch_img.png" width="50px" height="50px"
                                    alt="">
                            </div>
                            <div class="midea_phone">Time</div>
                            <div class="midea_text">10:00 a.m. to 07:00 p.m.</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12 mt-4">
                        <div class="midea_box">
                            <div class="midea_img">
                                <img src="../../../assets/img/zica/icon/location_img.png" width="50px" height="50px"
                                    alt="">
                            </div>
                            <div class="midea_phone">Address</div>
                            <div class="midea_text text-center">
                                <p>Mumbai: Zee Learn Limited, 135, Continental Building, B wing, Worli,Mumbai,
                                    Maharashtra 400 018.</p>
                                <!-- <p>Delhi: FC -19, 20, Upper Ground Floor, Film City, Sector 16A, Noida, Uttar Pradesh
                                    201 301</p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<footer class="desktopResponsive">
    <div class="footerContent">
        <div class="row">
            <div class="footerTextContnetn">
                <div class="subListContent">
                    <div class="row">
                        <div class="col-5">
                            <div class="zeeLogo">
                                <img src="../../../../assets/img/zica/ZLLLogo.png" alt="">
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="logoList">
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/ZICA_Logo.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/KidzeeFooterLogo.png" alt="">
                                </div>
                                <!-- <div class="logo">
                                <img src="../../../../assets/img/zica/mlwp.png" alt="">
                            </div> -->
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mlzs.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mls.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/zima.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-4">
                <div class="iconList">
                    <a href="https://www.facebook.com/zicaworld" target="_blank">
                        <img src="../../../../assets/img/zica/icon/icon1.png" alt="">
                    </a>
                    <a href="https://www.instagram.com/zeeinstituteofcreativeart/" target="_blank">
                        <img src="../../../../assets/img/zica/icon/icon2.png" alt="">
                    </a>
                    <a href="https://www.linkedin.com/school/zicaworld/" target="_blank">
                        <img src="../../../../assets/img/zica/icon/icon4.png" alt="">
                    </a>
                    <a href="https://www.youtube.com/user/ZICAWorld" target="_blank">
                        <img src="../../../../assets/img/zica/icon/icon5.png" alt="">
                    </a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="text-white text-center footerTextEmailId">
                    copyright ® 2023 Zee Learn Ltd. All rights reserved.
                </div>
            </div>
            <div class="col-md-4">
                <div class="text-white text-center footerTextEmailId">
                    <span class="footerRout" routerLink="./PrivacyPolicy">Privacy & Policy</span> <span
                        class="footerRout" routerLink="./termsOfUse">Terms of Use</span>
                </div>
            </div>
        </div>
    </div>

</footer>

<footer class="mobileResponsive">
    <div class="footerContent">
        <div class="row">
            <div class="footerTextContnetn">
                <div class="subListContent">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="zeeLogo">
                                <img src="../../../../assets/img/zica/ZLLLogo.png" alt="">
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="logoList">
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/ZICA_Logo.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/zima.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mlzs.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/KidzeeFooterLogo.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mls.png" alt="">
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-12">
                <div class="text-white text-center footerTextEmailId">
                    <span class="footerRout" routerLink="./PrivacyPolicy">Privacy & Policy</span><br><br> <span
                        class="footerRout" routerLink="./termsOfUse">Terms of Use</span>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-4">
                <div class="text-white text-center footerTextEmailId bottom_text">
                    copyright ® 2023 Zee Learn Ltd.<br> All rights reserved.
                </div>
            </div>

        </div>
        <div class="btn_content">
            <a href="tel:9152010880"><button class="btn admission_enq_btn" (click)="callScript()"><i class="fa fa-phone"></i>Call Us</button></a>
         <button class="btn admission_enq_btn" (click)="scrollToTop()">Start A Franchise </button>
        </div>
    </div>

</footer>